import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState } from "react";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";

Amplify.configure(awsconfig);

export function useTitle(title) {
  useEffect(() => {
    const prevTitle = document.title
    document.title = title
    return () => {
      document.title = prevTitle
    }
  })
}

function App() {
  useTitle("Lifemap")
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          life map 
        </p>
      </header>
    </div>
  );
}

export default App;
